
import { ApolloClient, InMemoryCache, gql, createHttpLink } from '@apollo/client';

export const getApolloClient = (uri) => {
    const httpLink = createHttpLink({
        uri: uri
      });
    const apolloClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache({
          addTypename: false
        }),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache'
          }
        },
        typeDefs: gql`
          enum OrderDirection {
            asc
            desc
          }
        `
      });
    return apolloClient
}
