export const currencies = {
  80001: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "TMATIC" },
  ],
  // 97: [
  //   { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
  // ],
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "TMATIC" },
  ],
};
