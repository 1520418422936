export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

//Launchpad Contract

export const contract = {
  80001: {
    poolfactory: "0xDF390B83FcCc4B697cac66B74753dCFdec4f445a",
    routeraddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    multicallAddress: "0x9C1b1c49627B5dD534C25e3E4914e6845875ea8D",
    lockAddress: "0xf31770AFC7B082d705d145a10bE1C9F471980D67",
    routername: "Sushiswap",
    feeReceiver: "0x9033606977d8E088fc744362Ee896fbE92479099",
    dividendTracker: "0xea3064b7aA485E999Cd6D5783578125525493B40",
  },
  // 97: {
  //   poolfactory: "0xdbA982F75afc05DC67Ef55709c93EF4F386f82cf",
  //   routeraddress: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
  //   multicallAddress: "0x7E126Df666b45bA069001e2Ab5fa409E17a8EC0f",
  //   lockAddress: "0xed6f77c1e7cda781b0daaba1a9a69bdff4b65ca5",
  //   routername: "Pancakeswap",
  //   feeReceiver: "0x9033606977d8E088fc744362Ee896fbE92479099",
  //   dividendTracker: "0xa748ae755f2fb4cc0bcba59657a49eb644803fc4",
  // },
  default: {
    poolfactory: "0xDF390B83FcCc4B697cac66B74753dCFdec4f445a",
    routeraddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    multicallAddress: "0x9C1b1c49627B5dD534C25e3E4914e6845875ea8D",
    lockAddress: "0xf31770AFC7B082d705d145a10bE1C9F471980D67",
    routername: "Sushiswap",
    feeReceiver: "0x9033606977d8E088fc744362Ee896fbE92479099",
    dividendTracker: "0xea3064b7aA485E999Cd6D5783578125525493B40",
  },
};
