// import binanceCoin from '../images/binance-coin.png';
import dyno from "../images/dyno.png";
import binanceCoin from "../images/binance-coin.png";

export const supportNetwork = {
  80001: {
    name: "Mumbai Testnet",
    chainId: 80001,
    rpc: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
    image: binanceCoin,
    symbol: "TMATIC",
    subgraph: "https://api.thegraph.com/subgraphs/name/seniorblockchaindev/gaspad",
  },
  // 97: {
  //   name: "Binance Smart Chain Testnet",
  //   chainId: 97,
  //   rpc: "https://data-seed-prebsc-1-s2.binance.org:8545",
  //   image: binanceCoin,
  //   symbol: "TBNB",
  //   subgraph: "https://api.studio.thegraph.com/query/39184/elevate/v0.0.08",
  // },
  // 56: {
  //   name: "Binance Smart Chain",
  //   chainId: 56,
  //   rpc: "https://bsc-dataseed.binance.org",
  //   image: binanceCoin,
  //   symbol: "BNB",
  //   subgraph: "https://api.studio.thegraph.com/query/39184/elevate/v0.0.08",
  // },
  default: {
    name: "Mumbai Testnet",
    chainId: 80001,
    rpc: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
    image: binanceCoin,
    symbol: "TMATIC",
    subgraph: "https://api.thegraph.com/subgraphs/name/seniorblockchaindev/gaspad",
  },
};

export const RPC_URLS = {
  80001: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
};


export const getChainId = (queryChainId, chainId) => {
  const _chainId_ = 
    supportNetwork[queryChainId ? queryChainId : chainId]
    ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
    : supportNetwork["default"].chainId
  return _chainId_
}

export const getSubgraphUrl = (chainId) => {
  const _chainId_ = 
    supportNetwork[chainId]
    ? supportNetwork[chainId].chainId
    : supportNetwork["default"].chainId
  return supportNetwork[_chainId_].subgraph
}
